import './App.css';
import Hero from './components/hero/hero';
import NAVBAR from './components/navigation/NAVBAR';
import Slogon1 from './components/slogon1/Slogon1';
import Slogon2 from './components/slogon2/Slogon2';
import Slogon3 from './components/slogon3/Slogon3';
import Slogon4 from './components/slogon4/Slogon4';
import Slogon5 from './components/slogon5/Slogon5';
import Slogon6 from './components/slogon6/Slogon6';
import Slogon7 from './components/slogon7/Slogon7';
import Slogon8 from './components/slogon8/Slogon8';
import Slogon9 from './components/slogon9/Slogon9';

import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import Circle from './Styled';
import { Container, Wrapper } from './components/slogon7/Slogon7.styled';

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }, []);

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)',
  });
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' });
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {loading ? (
        <Container>
          <Wrapper>
            <Circle />
            <Circle rotate={30} delay={-1.1} />
            <Circle rotate={60} delay={-1} />
            <Circle rotate={90} delay={-0.9} />
            <Circle rotate={120} delay={-0.8} />
            <Circle rotate={150} delay={-0.7} />
            <Circle rotate={180} delay={-0.6} />
            <Circle rotate={210} delay={-0.5} />
            <Circle rotate={240} delay={-0.4} />
            <Circle rotate={270} delay={-0.3} />
            <Circle rotate={300} delay={-0.2} />
            <Circle rotate={330} delay={-0.1} />
          </Wrapper>
        </Container>
      ) : (
        <>
          <NAVBAR isDesktopOrLaptop isBigScreen isTabletOrMobile />
          <Hero />
          <Slogon1 />

          <Slogon2 isTabletOrMobile={isTabletOrMobile} />
          <Slogon3 />
          <Slogon4 />
          <Slogon5 />
          <Slogon6 />
          <Slogon7 />
          <Slogon8 />
          <Slogon9 />
        </>
      )}
    </>
  );
}

export default App;
