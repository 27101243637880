import React, { useEffect } from 'react';
import NosAttouts from '../../assets/images/NosAttouts.png';
import { AttoUsContainer } from './Slogon5.styled';

import AOS from 'aos';
import 'aos/dist/aos.css';

import SVG1 from '../../assets/images/1.svg';

import SVG2 from '../../assets/images/2.svg';
import SVG3 from '../../assets/images/3.svg';
import SVG4 from '../../assets/images/4.svg';
import SVG5 from '../../assets/images/5.svg';
import SVG6 from '../../assets/images/6.svg';

const Slogon5 = () => {
  useEffect(() => {
    AOS.init({
      duration: 2000, // values from 0 to 3000, with step 50ms
      easing: 'ease', // default easing for AOS animations
    });
  }, []);
  return (
    <AttoUsContainer id="Atouts">
      <section class="h-auto w-full py-28">
        <div class="flex h-full flex-col items-center px-4">
          <div data-aos="fade-down">
            <h1 class="  text-3xl lg:text-7xl text-black font-bold">
              NOS <b className="text-[#66cccc]">ATOUTS</b>{' '}
            </h1>
          </div>
          <div class="lg:px-52 pt-8 lg:pt-16" data-aos="fade-up">
            <p class=" textb text-center text-xl lg:text-3xl text-black">
              Un format digital innovant dédié au dévelopement des compétences
              dans le domaine pharmaceutique
            </p>
          </div>
          <div class="flex flex-col lg:flex-row justify-around py-10 mx-0 lg:mx-10">
            <div class="flex ">
              <ul class="space-y-4">
                <li class="flex items-center" data-aos="fade-top">
                  <img
                    src={SVG2}
                    className="h-28 w-28  flex-none"
                    alt="Atouts"
                  />
                  <p class="textH ml-2 text-base md:text-lg lg:text-xl font-medium  ">
                    Des modules eLearning innovants
                  </p>
                </li>
                <li class="textH flex items-center" data-aos="fade-top">
                  <img
                    src={SVG3}
                    className="h-28 w-28  flex-none"
                    alt="Atouts"
                  />
                  <p class="textH ml-2 text-base md:text-lg lg:text-xl font-medium  ">
                    Des formations diversifiées
                  </p>
                </li>
              </ul>
            </div>
            <div class="flex ">
              <ul class="space-y-4">
                <li class="flex items-center" data-aos="fade-top">
                  <img
                    src={SVG4}
                    className="h-28 w-28  flex-none"
                    alt="Atouts"
                  />
                  <p class=" textH ml-2 text-base md:text-lg lg:text-xl font-medium  ">
                    Format 100% en ligne en accès illimité
                  </p>
                </li>
                <li class="flex items-center" data-aos="fade-top">
                  <img
                    src={SVG1}
                    className="h-28 w-28  flex-none"
                    alt="Atouts"
                  />
                  <p class="textH ml-2 text-base md:text-lg lg:text-xl font-medium  ">
                    Des experts métiers pour une pertinence scientifique
                  </p>
                </li>
              </ul>
            </div>
            <div class="flex ">
              <ul class="space-y-4">
                <li class="flex items-center" data-aos="fade-top">
                  <img
                    src={SVG5}
                    className="h-28 w-28  flex-none"
                    alt="Atouts"
                  />
                  <p class="textH ml-2 text-base md:text-lg lg:text-xl font-medium  ">
                    Adaptés à tous les secteurs et à tous les niveaux
                  </p>
                </li>
                <li class="flex items-center" data-aos="fade-top">
                  <img
                    src={SVG6}
                    className="h-28 w-28  flex-none"
                    alt="Atouts"
                  />
                  <p class="textH ml-2 text-base md:text-lg lg:text-xl font-medium  ">
                    Pédagogie active et interactive
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </AttoUsContainer>
  );
};

export default Slogon5;
