import React, { useEffect, useState } from 'react';
import { BannerContainer, P } from './Slogon2.styled';
import Phone from '../../assets/images/Graphic.png';
import Aos from 'aos';
import 'aos/dist/aos.css';

const Slogon2 = ({ isTabletOrMobile }) => {
  return (
    <BannerContainer id="QUIPHARMA">
      <div className="md:p-10 ">
        <div className="textb flex flex-wrap justify-around">
          <div
            className="flex  flex-col items-center justify-center py-4 md:py-10 md:w-1/2 w-full"
            data-aos="fade-top"
          >
            <P>
              <b className="text-[#66cccc]">PharmaForma</b> est le partenaire
              digital de la formation continue et du développement professionnel
              des pharmaciens et de leurs équipes, dans tous les secteurs
              d’activités pharmaceutiques : officine, industrie, hôpital,
              biologie...
            </P>
          </div>
          <div
            className="flex flex-col justify-center items-center"
            data-aos="fade-top"
          >
            <img src={Phone} alt="Phone" className="h-3/4" />
          </div>
        </div>
      </div>
    </BannerContainer>
  );
};

export default Slogon2;
