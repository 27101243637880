import React, { useEffect, useState } from 'react';
import Logo from '../../assets/images/logo/logo1.png';

const NAVBAR = (props) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const handleToggle = () => {
    setIsActive(!isActive);
  };
  const changeBackground = () => {
    if (window.scrollY >= 160) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };
  useEffect(() => {
    changeBackground();
    // adding the event when scroll change background
    window.addEventListener('scroll', changeBackground);
  }, []);

  return (
    <nav
      className={`top-0 transition ease-in-out duration-100 fixed z-50 w-full flex flex-wrap items-center justify-between px-2 py-2  ${
        !isScrolled ? 'bg-transparent' : 'bg-gray-100'
      }`}
    >
      <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
        <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start ">
          <a
            className={`text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase pt-2 ${
              !isScrolled ? 'text-white' : 'text-gray-800'
            }`}
            href="#!"
          >
            <img
              src={Logo}
              className={`${props.isTabletOrMobile && 'h-11 '} h-14`}
            />
          </a>
          <button
            className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
            type="button"
            onClick={() => setIsMobile(!isMobile)}
          >
            <i
              className={`${
                !isScrolled ? 'text-white' : 'text-gray-800'
              } fas fa-bars`}
            ></i>
          </button>
        </div>
        <div
          className={`lg:flex flex-grow items-center ${
            isScrolled ? 'bg-white' : 'bg-gray-800'
          } lg:bg-opacity-0 lg:shadow-none  ${isMobile ? 'block' : 'hidden'}`}
          id="example-collapse-navbar"
        >
          <ul className="flex flex-col lg:flex-row list-none lg:ml-auto items-center">
            <li className="inline-block relative textH">
              <a
                className={`${
                  !isScrolled ? 'text-white' : 'text-gray-800'
                } hover:text-[#66cccc]  px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold pointer`}
                href="#Hero"
                onClick={() => setIsMobile(false)}
              >
                Accueil
              </a>
            </li>
            <li className="inline-block relative pointer textH">
              <a
                className={`p ${
                  !isScrolled ? 'text-white ' : 'text-gray-800'
                } hover:text-[#66cccc]   px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold  `}
                onClick={handleToggle}
              >
                A propos de nous
              </a>
              <div
                className={`${
                  isActive ? 'absolute' : 'hidden'
                } bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48 `}
                onClick={() => {
                  handleToggle();
                  setIsMobile(!isMobile);
                }}
              >
                <a
                  href="#QUIPHARMA"
                  className={`textH text-gray-800 hover:text-[#66cccc] pointer  px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold`}
                >
                  Qui est PHARMAFORMA
                </a>
                <a
                  href="#Mission"
                  className={`textH text-gray-800 hover:text-[#66cccc] pointer  px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold`}
                >
                  Notre mission
                </a>
                <a
                  href="#Valeur"
                  className={` textH text-gray-800 hover:text-[#66cccc]  pointer px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold`}
                >
                  Nos valeurs
                </a>
                <a
                  href="#Atouts"
                  className={`textH text-gray-800 hover:text-[#66cccc] pointer px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold`}
                >
                  Nos Atouts
                </a>
                <a
                  href="#Equipe"
                  className={`textH text-gray-800 hover:text-[#66cccc] pointer  px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold`}
                >
                  Notre équipe
                </a>
              </div>
            </li>
            <li className="textH inline-block relative">
              <a
                className={`${
                  !isScrolled ? 'text-white' : 'text-gray-800'
                } hover:text-[#66cccc]  px-3 py-4 lg:py-2 flex items-center  pointer text-xs uppercase font-bold`}
                href="#Formations"
                onClick={() => setIsMobile(false)}
              >
                Nos Formations
              </a>
            </li>
            <li className="textH inline-block relative">
              <a
                className={`${
                  !isScrolled ? 'text-white' : 'text-gray-800'
                } hover:text-[#66cccc]  px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold pointer`}
                href="#Contact"
                onClick={() => setIsMobile(false)}
              >
                Contact
              </a>
            </li>

            {/* <li className="flex items-center">
              <a
                className={`${
                  !isScrolled ? 'text-white' : 'text-gray-800'
                } hover:text-[#66cccc]  px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold`}
                href="#"
                onClick={() => setIsMobile(false)}
              >
                <i className="lg:text-blueGray-200 text-blueGray-400 fab fa-facebook text-lg leading-lg"></i>
                <span className="lg:hidden inline-block ml-2">Facebook</span>
              </a>
            </li>
            <li className="flex items-center">
              <a
                className={`${
                  !isScrolled ? 'text-white' : 'text-gray-800'
                } hover:text-[#66cccc]  px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold`}
                href="#"
                onClick={() => setIsMobile(false)}
              >
                <i className="lg:text-blueGray-200 text-blueGray-400 fab fa-linkedin text-lg leading-lg"></i>
                <span className="lg:hidden inline-block ml-2">LinkedIn</span>
              </a>
            </li>
            <li className="flex items-center">
              <a
                className={`${
                  !isScrolled ? 'text-white' : 'text-gray-800'
                } hover:text-[#66cccc]  px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold`}
                href="#"
                onClick={() => setIsMobile(false)}
              >
                <i className="lg:text-blueGray-200 text-blueGray-400 fab fa-twitter text-lg leading-lg"></i>
                <span className="lg:hidden inline-block ml-2">Tweet</span>
              </a>
            </li> */}

            <li className="flex items-center textH">
              <a
                className="bg-white text-blueGray-700 hover:bg-[#66cccc]  active:bg-blueGray-50 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
                href="https://connexion.pharmaforma.com"
                onClick={() => setIsMobile(false)}
              >
                Se Connecter  <i className="esp fas fa-arrow-alt-circle-right"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NAVBAR;
