import React from 'react';
import VideoBg from '../../assets/videos/hero225.mp4';
import { HeroBg, HeroContainer } from './hero.styled';
const Hero = () => {
  return (
    <HeroContainer id="Hero">
      <HeroBg autoPlay loop muted src={VideoBg} type="video/mp4" />
    </HeroContainer>
  );
};

export default Hero;
