import React, { useEffect } from 'react';
import { BannerContainer, H1 } from './Slogon3.styled';
import Mission from '../../assets/images/mission.svg';
import Vision from '../../assets/images/vision.svg';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Slogon3 = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <BannerContainer>
      <div
        class="flex   w-full flex-col justify-center items-center lg:flex-row p-2 md:p-28"
        id="Mission"
      >
        <div class="flex flex-col items-center   justify-center p-2 md:p-4">
          <img
            class="w-28 md:w-44  "
            src={Mission}
            alt="mission"
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="1500"
          />
          <span class="inline-flex p-2 md:p-8" data-aos="fade-left">
            <h1 class="text-2xl font-bold md:text-5xl  notre-equipe-title">
              NOTRE
            </h1>
            &nbsp;
            <h1 class="text-2xl font-bold text-[#66cccc] md:text-5xl  notre-equipe-title ">
              MISSION
            </h1>
          </span>
          <p
            className=" textb font-serif leading-6 text-xl lg:text-3xl  text-center "
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
          >
            <b class="textb text-[#66cccc]"> PharmaForma</b> vous accompagne tout au
            long de votre carrière pour consolider vos connaissances, vous faire
            monter en compétence et vous développer professionnellement.
          </p>
        </div>
        <div class="flex flex-col  items-center justify-center p-2 md:p-4">
          <img
            class="w-28 md:w-44  "
            src={Vision}
            alt="mission"
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration="1500"
          />
          <span class="inline-flex p-2 md:p-8" data-aos="fade-right">
            <h1 class=" text-2xl font-bold md:text-5xl  notre-equipe-title">
              NOTRE
            </h1>
            &nbsp;
            <h1 class=" text-2xl font-bold text-[#66cccc] md:text-5xl  notre-equipe-title">
              VISION
            </h1>
          </span>
          <p
            className="textb font-serif leading-6 text-xl lg:text-3xl  text-center "
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
          >
            <b class="textb text-[#66cccc]"> PharmaForma</b> leader en matière de
            formation en ligne pour les pharmaciens et leurs équipes grâce à ses
            expertises pharmaceutiques et technologiques.
          </p>
        </div>
      </div>
    </BannerContainer>
  );
};

export default Slogon3;
