import React, { useEffect } from 'react';
import { H1, IMG, IMGSMALL, ValeursContainer } from './Slogon4.styled';
import DIAMANT from '../../assets/images/diamontgrand.png';
import SVGLINE from './SvgLine.svg';
import Aos from 'aos';
import 'aos/dist/aos.css';

const Slogon4 = () => {
  useEffect(
    function () {
      Aos.init({ duration: 1000 });
    },
    [window.scroll]
  );
  return (
    <ValeursContainer id="Valeur">
      <div className="  md:pb-12">
        <div className="flex justify-center ">
          <div
            className="  flex flex-col justify-center items-center w-full"
            data-aos="fade-up"
          >
            <img
              src={DIAMANT}
              alt="Diamant"
              className="object-cover mb-4 w-16 md:w-44"
            />
            <H1>NOS VALEURS</H1>
            <img src={SVGLINE} alt="valeurs" />
          </div>
        </div>
      </div>
      <div className="parent">
        <div
          className="div1 flex flex-col justify-center items-center pl-4"
          data-aos="fade-down"
        >
          <img
            className="object-cover mb-2 w-10 md:w-16"
            src={DIAMANT}
            alt="Diamant"
          />
          <h1 className="textb text-white font-bold font-serif text-xl ">
            Créativité
          </h1>
        </div>

        <div
          className="div2 flex flex-col justify-center items-center"
          data-aos="fade-up"
        >
          <img
            className="object-cover mb-2 w-10 md:w-16"
            src={DIAMANT}
            alt="Diamant"
          />
          <h1 className="textb text-white font-bold font-serif text-xl">Expertise</h1>
        </div>

        <div
          className="div3 flex flex-col justify-center items-center"
          data-aos="fade-down"
        >
          <img
            className="object-cover mb-2 w-10 md:w-16"
            src={DIAMANT}
            alt="Diamant"
          />
          <h1 className="textb text-white font-bold font-serif text-xl ">
            Excellence
          </h1>
        </div>

        <div
          className="div4 flex flex-col justify-center items-center"
          data-aos="fade-up"
        >
          <img
            className="object-cover mb-2 w-10 md:w-16"
            src={DIAMANT}
            alt="Diamant"
          />
          <h1 className="textb text-white font-bold font-serif text-xl ">
            Engagement
          </h1>
        </div>

        <div
          className="div5 flex flex-col justify-center items-center"
          data-aos="fade-down"
        >
          <img
            className="object-cover mb-2 w-10 md:w-16"
            src={DIAMANT}
            alt="Diamant"
          />
          <h1 className="textb text-white font-bold font-serif text-xl ">
            Innovation
          </h1>
        </div>
      </div>
    </ValeursContainer>
  );
};

export default Slogon4;
