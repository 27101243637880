import React from 'react';
import { FormationContainer } from './Slogon7.styled';
import FormationImg from '../../assets/images/formationBanner.png';
import { H1, H2 } from '../slogon3/Slogon3.styled';
import Correct from './correct.svg';
const Slogon7 = () => {
  return (
    <FormationContainer>
      <div
        className="max-w-[1140px] xl:max-w-[1800px] 2xl:max-w-[2140px] md:h-screen mx-auto flex flex-col justify-center items-center"
        data-aos="fade-up"
        id="Formations"
      >
        <h1 className="headingFormationText p-6">
          Une plateforme de formation e-learning pour les pharmaciens et leurs
          équipes
        </h1>

        <div
          className="flex justify-around items-center flex-col md:flex-row"
          data-aos="fade-up"
        >
          <div className="pr-10 pl-20">
            <img src={FormationImg} alt="formations " />
          </div>
          <div className="pt-12 md:pt-0 px-6">
            <ul>
              <li className="flex items-center">
                <img src={Correct} alt="correct" className="w-4 mr-2" />
                <p className="textb text-xs sm:text-base md:text-xl ">
                  Une plateforme accessible 24h/24 7j/7
                </p>
              </li>
              <li className="flex items-center py-14 ">
                <img src={Correct} alt="correct" className="w-4 mr-2" />
                <p className=" textb text-black  font-serif text-xs sm:text-base md:text-xl ">
                  Vos formations accessibles depuis n’importe quel appareil
                </p>
              </li>

              <li className="flex items-center">
                <img src={Correct} alt="correct" className="w-4 mr-2" />
                <p className="textb text-black  font-serif text-xs sm:text-base md:text-xl ">
                  Un catalogue de formations riche et diversifié
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </FormationContainer>
  );
};

export default Slogon7;
