import React, { useEffect, useState } from 'react';
import {
  Article,
  Container,
  DIV1,
  DIV2,
  DIV3,
  EquipeContainer,
  ResponsiveH1,
  SectionContainer,
} from './Slogon6.styled';
import EquipeSvg from '../../assets/images/Equipe.svg';
import Title from '../../assets/images/title.svg';
import Text from '../../assets/images/text.svg';
import UL from './UL.svg';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useMediaQuery } from 'react-responsive';

const Slogon6 = () => {
  const [collapse, setCollapset] = useState(false);
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)',
  });
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' });
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

  const handleCollapse = () => {
    setCollapset(!collapse);
  };
  useEffect(() => {
    if (isTabletOrMobile) {
      setCollapset(true);
    }
    AOS.init({
      duration: 2000, // values from 0 to 3000, with step 50ms
      easing: 'ease', // default easing for AOS animations
    });
  }, []);

  return (
    <EquipeContainer id="Equipe">
      <div className="max-w-[1140px] xl:max-w-[1800px] 2xl:max-w-[2140px] md:h-full mx-auto flex flex-wrap justify-center  ">
        <div className="flex flex-col justify-start items-center px-12">
          <div className="mt-6 md:pb-4 mb-4" data-aos="fade-down">
            <ResponsiveH1 className="text-xl md:text-2xl lg:text-3xl xl:text-5xl ">
              NOTRE <span className="text-[#66cccc]">ÉQUIPE</span>
            </ResponsiveH1>
          </div>
          <div className="md:pt-4" data-aos="fade-right">
            <img src={EquipeSvg} alt="title" className="h-full" />
          </div>
        </div>
        <div className="pl-4 pt-14 w-full xl:w-3/5  xl:pt-28">
          <ul>
            <li className="flex items-center ">
              <img src={UL} alt="correct" className="w-6 mr-4" />
              <p className="textb text-black  font-serif text-xs sm:text-base md:text-xl ">
                Des spécialistes du eLearning qui font appel à des innovations
                pédagogiques et de nouvelles technologies pour des contenus
                interactifs
              </p>
            </li>
            <li className="flex items-center py-14  ">
              <img src={UL} alt="correct" className="w-6 mr-4" />
              <p className="textb text-black  font-serif text-xs sm:text-base md:text-xl ">
                Des experts santé qui mettent leur savoir-faire à votre service
                pour créer des contenus scientifiques actualisés
              </p>
            </li>

            <li className="flex items-center pb-14">
              <img src={UL} alt="correct" className="w-6 mr-4" />
              <p className="textb text-black  font-serif text-xs sm:text-base md:text-xl ">
                Nos programmes sont le fruit d’un long travail de collaboration
                entre les experts métier et les spécialistes e-learning.
              </p>
            </li>
            <li className="flex items-center">
              <img src={UL} alt="correct" className="w-6 mr-4" />
              <p className="textb text-black  font-serif text-xs sm:text-base md:text-xl ">
                Une équipe commerciale à votre écoute
              </p>
            </li>
          </ul>
        </div>
      </div>
    </EquipeContainer>
  );
};

export default Slogon6;
