import React, { useEffect } from 'react';
import { SLOGON_CONTAINER } from './Slogon1.styled';
import bgSecTwo from '../../assets/images/section-22.jpg';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Slogon1 = () => {
  useEffect(() => {
    AOS.init({
      duration: 2000, // values from 0 to 3000, with step 50ms
      easing: 'ease', // default easing for AOS animations
    });
  }, []);
  return (
    <SLOGON_CONTAINER>
      <img
        src={bgSecTwo}
        alt="bgSecTwo"
        className="w-full "
        data-aos="fade-down"
      />
    </SLOGON_CONTAINER>
  );
};

export default Slogon1;
