import styled from 'styled-components';

export const HeroContainer = styled.header`
  background: #0c0c0c;

  height: 100vh;
`;

export const HeroBg = styled.video`
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  background: #232a34;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
